import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import axios from 'axios';
import { BASE_URL } from './baseUrl';
import { SITE_URL } from './siteUrl';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const BlogDetailsCont = styled.div`
 width:100%;
`
const BlogInnerCont = styled.div`
 width: 100%;
 h1 {
    font-size: 30px;
 }
 h1,h2,h3,h4 {
    margin-bottom: 8px;
 }
 span {
    color: #aaa;
    font-size: 14px;
    
 }
 img {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
 }

 p {
    color: #666;
    margin-top: 10px;
    margin-bottom: 25px;
 }
`
const CouseList = styled.div`
  width:100%;
  background:#f5f5f5;
  min-height: 200px;
  border-radius: 4px;
  margin-top: 80px;

 h2 {
    width: 100%;
    padding: 10px 10px 10px 20px;
    background-color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    color: #fff;

    small {
        font-weight: 400;
        font-size: 14px;
    }
 }

 ul {
    width: 100%;
    padding-bottom: 10px;

    li {
     width: 100%;
    }

    li:last-child a {
        border-bottom: none !important;
    }

    li a {
        color: #333;
        font-size: 16px;
        padding: 10px 10px 10px 25px;
        border-bottom: solid 1px #e9e9e9;
        display: block;
        transition: all 0.4s;
        position: relative;

        &:hover {
            padding-left: 35px;

            &::after {
                width: 100%;
            }
        }

        &::after {
            position: absolute;
            content: '';
            bottom:0;
            left: 0;
            right: 0;
            width: 0;
            height: 1px;
            background: #999;
            transition: all 0.4s;
            
        }
    }

    li:last-child a:hover {
        &::after {
          width: 0;
        }
    }
 }

`

function BlogDetails() {

    const [inb, setInb] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/blogbanner`).then(
            res => setInb(res.data)
        )
    }, []);

    //Blog Details
    const [data, setData] = useState({
        title: "",
        content: "",
        image: "",
        alt: "",
        post_date: "",
        page_link: "",
        page_title:"",
        keywords:"",
        description: "",
    })

   
    const { page_link } = useParams();

    useEffect(() => {
        axios.get(`${BASE_URL}/blogs/${page_link}`).then(
            res => setData(res.data)
        )
    }, [page_link])

    const  [blog, setBlog] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/blog`).then(
            res => setBlog(res.data)
        )
    }, []);

    return (
        <>
        <HelmetProvider>
         <Helmet>
           <title>{data.page_title}</title>
           <meta name="keywords" content={data.keywords}/>
           <meta name="description" content={data.description}/>
         </Helmet>
         </HelmetProvider>
            {inb.map(item =>
                <div className="inner-banner" key={item.id}>
                    <img src={`${SITE_URL}/images/innerbanners/${item.image}`} alt={`${item.alt}`} />
                    <div className="inner-banner-cont">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <h1>{item.title}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            )}
            <BlogDetailsCont className='inner-sec'>
                <Container>
                    <Row>
                       <Col lg={8} md={8}>
                        <BlogInnerCont>
                          <h1>{data.title}</h1>
                          <span>Post Date: {data.post_date}</span>
                          <img src={`${SITE_URL}/images/blog/${data.image}`}alt={`${data.alt}`}/>
                          <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
                          </BlogInnerCont>
                        </Col> 
                       
                        <Col lg={4} md={4}>
                        <CouseList>
                                <h2>Recent Posts</h2>
                                <ul>
                                    {blog.slice(0, 10).map(item=>
                                        <li key={item.id}><NavLink to={`/blog/${item.page_link}`}>{item.title.substr(0, 45)+ '...'}</NavLink></li>
                                        )}
                                     
                                </ul>
                            </CouseList>
                        </Col>
                    </Row>
                </Container>
            </BlogDetailsCont>
        </>
    )
}


export default BlogDetails;