import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { device } from '../Device';
import axios from 'axios';
import { BASE_URL } from '../baseUrl';
import { SITE_URL } from '../siteUrl';


const BlogSection = styled.section`
width: 100%;
h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    text-align: center;
    margin-top: 25px;
}
`
const BlogCont = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: 255px;
    object-fit: cover;
  }

  .blog-date {
    text-align: center;
    padding-right: 20px;
    padding-top: 10px;
    font-size: 16px;
    background: rgba(255,255,255,0.8);
    z-index: 10;
    margin-top: -45px;
    position: relative;
    padding: 7px 10px;
    color: #000;
    width: 65%;
    margin-left: auto;
    border-radius: 5px 0px 0px 5px;
    

  }
  
  .blog-inner {
    width: 100%;
    padding: 15px 20px 20px 20px;
    background: #fff;
    min-height: 200px;
    box-shadow: rgba(0, 0, 0, 0.1) 4px 9px 25px -10px;
    border-radius: 0 0 5px 5px;

    
  h1 {
    line-height:22px;
    margin-bottom: 20px;
    a {
    color: #333;
    font-size: 20px;
    font-weight: 500;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
  }
  }

  p {
    line-height: 26px;
  }
  
  .blog-link {
    margin-top: 20px;
    border-top: solid 1px #f0f0f0;
    padding-top: 15px;
    color: #333;
    i {
        color: ${({ theme }) => theme.colors.primary};
        font-size: 14px;
        padding-right: 3px;
        @media ${device.xs} {
        display: none;
       }
    }
  a {
    color: #333;
    transition: all 0.3s ease;
    font-size: 16px;
    i {
        color: #333;
    }
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
    
    @media ${device.md} {
    font-size: 14px;
  }
  }
  @media ${device.md} {
    font-size: 14px;
  }
}

  }
  
`


function HomeBlog() {
    const regex = /(<([^>]+)>)/ig;
    const [blog, setBlog] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/blog`).then(
            res => setBlog(res.data)
        )
    }, []);

    return (
        <>
            <BlogSection className="inner-sec">
                <Container>
                    <Row>
                        <Col lg={12} className="pc-title">
                            <div className="title text-center">
                                <h1>Latest News and Events</h1>
                                <h2>Blog</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        {blog.slice(0, 3).map(item =>
                            <Col key={item.id} lg={4} md={6} className="mt-4">
                                <BlogCont>
                                <NavLink to={`/blog/${item.page_link}`}><img src={`${SITE_URL}/images/blog/${item.image}`} alt={item.alt} /></NavLink>
                                    <div className="blog-inner">
                                        <h1><NavLink to={`/blog/${item.page_link}`}>{item.title}</NavLink></h1>
                                        {/* <p>{item.content.replace(regex, '')}</p> */}
                                        <div dangerouslySetInnerHTML={{ __html: item.content.substr(0, 150) + '...' }}></div>
                                        <div className="blog-link">
                                            <Row>
                                                <Col xs={7}>
                                                    <i className="bi bi-calendar4-week"></i> {item.post_date}
                                                </Col>
                                                <Col xs={5}>
                                                    <NavLink to={`/blog/${item.page_link}`}>Read More <i className="bi bi-arrow-right"></i></NavLink>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </BlogCont>
                            </Col>
                        )}

                    </Row>
                </Container>
            </BlogSection>

        </>
    )
}

export default HomeBlog;